@import './styles/breakpoints.module';

.root {
  padding: 0 16px;
  margin-left: auto;
  margin-right: auto;
}

.xs {
  max-width: 540px;
}

.sm {
  max-width: 720px;
}

.md {
  max-width: 960px;
}

.lg {
  max-width: 1140px;
}

.xl {
  max-width: 1320px;
}


.fluid {
  max-width: 100%;
}
