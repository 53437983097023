@import './styles/colors.module';
@import './styles/breakpoints.module';

.navigation {
  width: 100%;
  background-color: $purple;
  height: 96px;

  @media screen and (max-width: $md) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.navigationInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;

  @media screen and (max-width: $md) {
    flex-direction: column;
    height: auto;
  }
}

.logo {
  display: block;
  max-width: 100px;

  @media screen and (max-width: $md) {
    margin-bottom: 15px;
  }
}

.logoImage {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.navigationGlobals {
  display: flex;
  align-items: center;

  @media screen and (max-width: $sm) {
    flex-wrap: wrap;
  }
}

.navigationGlobalsLinks {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $sm) {
    flex-wrap: wrap;
    flex: 0 0 100%;
    margin: -5px;
  }
}

.navigationGlobalLink {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  opacity: 0.7;
  transition: opacity 100ms ease;
  white-space: nowrap;

  &:hover {
    opacity: 1;
  }

  & + & {
    margin-left: 15px;
  }

  @media screen and (max-width: $sm) {
    margin: 5px;
    margin-left: 5px !important;
  }
}

.navigationSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: $md) {
    align-items: center;
    flex-direction: column-reverse;
  }
}

.navigationMain {
  display: flex;
  align-items: center;
  margin-top: 25px;

  @media screen and (max-width: $md) {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: $sm) {
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -5px;
    margin-left: -5px;
  }
}

.navigationMainLink {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: bold;
  opacity: 0.9;
  transition: opacity 100ms ease;
  white-space: nowrap;

  &:hover {
    opacity: 1;
  }

  & + & {
    margin-left: 15px;
  }

  @media screen and (max-width: $sm) {
    margin: 5px;
    margin-left: 5px !important;
  }
}
