$dark-blue: #171C8F;
$dark-blue-hover: #121672;
$burn-blue: #2839d0;
$blue: #009ACE;
$charcoal: #3D3935;
$light-gray: #EFEFED;
$blue-hover: #f3f4f9;
$semi-light-gray: #C4C4C4;
$gray: #868685;
$text-gray: #666666;
$orange: #FFA300;
$red: #E03C31;
$red-hover: #fff8f8;
$purple: #3f2a56;
$invisalign: #2ad2c9;
