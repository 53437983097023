@import './styles/colors.module';
@import './styles/breakpoints.module';

.footer {
  background-color: #3d3935;
  height: 100px;
  margin-top: 50px;

  @media screen and (max-width: $md) {
    height: auto;
  }
}

.footerInner {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $md) {
    flex-direction: column;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.footerSection {
  @media screen and (max-width: $md) {
    & + & {
      margin-top: 20px;
    }
  }
}

.footerLogos {
  display: flex;
  align-items: center;

  @media screen and (max-width: $md) {
    justify-content: center;
  }
}

.footerLogo {
  display: block;
  height: 30px;
  opacity: 0.9;
  transition: opacity 100ms ease;

  &:hover {
    opacity: 1;
  }

  img {
    max-height: 100%;
  }

  & + & {
    border-left: 1px solid #fff;
    padding-left: 25px;
    margin-left: 25px;
  }

  @media screen and (max-width: $md) {
    height: 25px;

    & + & {
      padding-left: 15px;
      margin-left: 15px;
    }
  }
}

.logoImage {
  position: relative !important;
  width: auto !important;
  height: 100% !important;
}

.footerCopyright {
  margin-top: 15px;
  opacity: 0.8;
  display: flex;

  @media screen and (max-width: $md) {
    flex-wrap: wrap;
  }

  & * {
    color: #9e9c9a;
    font-size: 13px;
  }

  a {
    margin-left: 15px;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
}
