@import './styles/colors.module';
@import './styles/breakpoints.module';

.anchor {
  font-family: Roboto, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: inherit;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  transition: color 150ms ease;

  @media (max-width: $sm) {
    font-size: 14px;
  }
}
